// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'OnboardingNavMenu',
  data() {
    return {
      links: [
        {
          id: 'menu',
          label: 'Crear carta'
        },
        {
          id: 'category',
          label: 'Crear categoría'
        },
        {
          id: 'product',
          label: 'Crear producto'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('authentication', ['onboardingSections']),
    /**
     * Obtengo los enlaces preparados
     *
     * @return {Array}
     */
    parsedLinks() {
      return this.onboardingSections && this.onboardingSections.length > 0
        ? this.links.map((link) => {
            const currentSection = this.onboardingSections.find((section) => section.id === link.id)

            return {
              ...currentSection,
              label: link.label || 'No definido'
            }
          })
        : []
    }
  }
}
