// constant
import { THEME_SECONDARY_COLOR } from '@/constants'
// Components
import AppBar from '../AppBar'
import AppNavDrawer from '../AppNavDrawer'
import AppNavMenu from '../AppNavMenu'
import OnboardingNavMenu from '../OnboardingNavMenu'
import HeaderNavMenu from '../HeaderNavMenu'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'OnboardingNavBar',
  components: {
    AppBar,
    AppNavDrawer,
    AppNavMenu,
    OnboardingNavMenu,
    HeaderNavMenu
  },
  data() {
    return {
      progressColor: THEME_SECONDARY_COLOR,
      visible: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['onboardingSections']),
    /**
     * Obtenemos los datos para pintar el círculo
     *
     * @return {Object}
     */
    progressCircularOptions() {
      const total =
        this.onboardingSections && this.onboardingSections.length > 0
          ? this.onboardingSections.length
          : 0
      const completed =
        this.onboardingSections && this.onboardingSections.length > 0
          ? this.onboardingSections.reduce((sumPor, section) => {
              if (section.completed) {
                sumPor += 1
              }
              return sumPor
            }, 0)
          : 0

      return {
        completed: total > 0 ? Math.ceil((completed / total) * 100) : 0,
        difference: Math.ceil(100 - (completed / total) * 100)
      }
    },
    /**
     * Texto que acompaña al circulo de progreso
     *
     * @return {string}
     */
    labelProcessCircular() {
      const progressCircularOptions = this.progressCircularOptions

      return progressCircularOptions.completed === 100
        ? 'El proceso de iniciación ha terminado, puedes consultar tu carta y conocer mucho más sobre Bakarta.'
        : `Estás a un ${progressCircularOptions.difference}% de liberar todo el poder de Bakarta.`
    }
  },
  methods: {
    /**
     * Change the visibility of navDrawer
     *
     * @param {Boolean} show - show or hide navDrawer
     */
    handleVisibility(show) {
      this.visible = show
    }
  }
}
